@import '../../../scss/_variables.scss';

.footer {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0% 100%);

  svg {
    vertical-align: middle;
  }

  @media #{$thin},#{$xthin} {
    padding: 1.5rem 1rem 1rem 1rem;

    span {
      font-size: 0.8rem;
    }

    svg {
      margin-right: 0.5rem;
      font-size: 1.6rem;
    }

    img {
      vertical-align: middle;
      margin-right: 0.5rem;
      height: 1.6rem;
      width: 1.6rem;
    }
  }

  @media #{$small-thin},#{$small-wide} {
    padding: 0.7rem 1rem 0.5rem 0.5rem;

    span {
      font-size: 0.7rem;
    }

    svg {
      margin-right: 0.5rem;
      font-size: 1.4rem;
    }

    img {
      vertical-align: middle;
      margin-right: 0.5rem;
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  @media #{$wide} {
    padding: 2.5rem 3rem 2.5rem 0rem;

    span {
      font-size: 1rem;
    }

    svg {
      margin-right: 1rem;
      font-size: 2.5rem;
    }

    img {
      vertical-align: middle;
      margin-right: 1rem;
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
