#app-container {
  background-image: url('./assets/images/paris-background.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;

  > div:nth-child(1) {
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
  }

  #tree-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: url('./assets/images/background-tree.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
  }
}
