@import '../../scss/variables';

#description-container {
  position: relative;
  display: flex;
  pointer-events: none;

  #layout-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 50%;
  }

  #section-container {
    display: flex;
  }

  button {
    box-sizing: border-box;
    position: relative;
    border: none;
    outline: none;
    color: white;
    padding: 0.2rem 0 0 0;
    transition: all 0.2s ease;
    cursor: pointer;

    svg {
      font-size: 3rem;
    }

    #home-svg {
      font-size: 2rem;
    }
  }

  .description-section {
    width: 25%;

    h3 {
      margin: 0;
      padding: 0;
      filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.3));
      opacity: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2);
      pointer-events: all;

      svg {
        vertical-align: middle;
        margin-left: 0.2rem;
        margin-bottom: 0.2rem;
      }
    }

    p {
      font-weight: 300;
      font-size: 1.1rem;
      margin: 1rem 0 0.8rem 0;
      filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.3));
      opacity: 0;
      text-align: justify;
    }

    div {
      opacity: 0;
      display: flex;
      flex-wrap: wrap;

      span {
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 0.5rem;
        filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
      }
    }
  }

  #carousel-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 0.2rem;

      &:hover {
        transform: scale(1.5);
      }
    }

    .carousel-selected-btn {
      transform: scale(1.5);
      background-color: #fff;
      border-radius: 0;
    }
  }

  @media #{$wide} {
    align-items: center;
    height: calc(100vh - 15rem);
    height: calc(var(--vh, 1vh) * 100 - 15rem);

    #layout-container {
      padding-bottom: 0rem;
    }

    #section-container {
      width: calc(100% - 2% - 10rem);
      justify-content: flex-end;
    }

    button {
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.2);
      width: 4rem;
      height: 4rem;
      margin: 0 1%;
      border-radius: 50%;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    #empty-nav-btn {
      margin: 0 1%;
      width: 4rem;
    }

    .description-section {
      margin-right: 2%;

      h3 {
        font-weight: 600;
        font-size: 2.2rem;
      }

      div {
        span {
          font-size: 0.8rem;
          margin-right: 0.5rem;
          margin-top: 0.3rem;
          padding: 0.2rem 0.6rem;
        }
      }
    }

    #carousel-container {
      button {
        height: 0.2rem;
        width: 1rem;
        margin: 0rem 0.5rem;
      }
    }
  }

  @media #{$thin},#{$xthin} {
    align-items: flex-end;
    height: calc(100vh - 11rem);
    height: calc(var(--vh, 1vh) * 100 - 11rem);

    #layout-container {
      padding-bottom: 1rem;
    }

    #section-container {
      justify-content: center;
      width: calc(100% - 6rem);
    }

    button {
      z-index: 3;
      background-color: transparent;
      width: 3rem;
      height: 3rem;
      margin: 0;
      border-radius: 0%;

      &:hover {
        background-color: transparent;
      }

      svg {
        font-size: 2rem;
      }

      #home-svg {
        font-size: 1.5rem;
      }
    }

    #empty-nav-btn {
      margin: 0;
      width: 3rem;
    }

    .description-section {
      margin: 0;

      h3 {
        font-weight: 700;
        font-size: 1.2rem;
      }

      p {
        font-size: 0.8rem;
        margin: 0.8rem 0 0.5rem 0;
      }

      div {
        span {
          font-size: 0.75rem;
          margin-right: 0.2rem;
          margin-top: 0.15rem;
          padding: 0.2rem 0.2rem;
        }
      }
    }

    #carousel-container {
      button {
        height: 0.1rem;
        width: 0.5rem;
        margin: 0rem 0.4rem;
      }
    }
  }

  @media #{$thin} {
    .description-section {
      width: 80%;
    }
  }

  @media #{$xthin} {
    .description-section {
      width: 100%;
    }
  }

  @media #{$small-wide}, #{$small-thin} {
    align-items: center;
    height: calc(100vh - 7rem);
    height: calc(var(--vh, 1vh) * 100 - 7rem);

    #layout-container {
      padding-bottom: 0rem;
    }

    #section-container {
      width: calc(100% - 2% - 6rem);
      justify-content: flex-end;

      .description-section {
        width: 30%;
      }
    }

    button {
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.2);
      width: 3rem;
      height: 3rem;
      margin: 0 1%;
      border-radius: 50%;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      svg {
        font-size: 2rem;
      }

      #home-svg {
        font-size: 2rem;
      }
    }

    #empty-nav-btn {
      margin: 0 1%;
      width: 3rem;
    }

    .description-section {
      h3 {
        font-weight: 600;
        font-size: 1.2rem;
      }

      p {
        font-weight: 300;
        font-size: 0.7rem;
        margin: 0.8rem 0 0.4rem 0;
      }

      div {
        span {
          font-size: 0.6rem;
          margin-right: 0.2rem;
          margin-top: 0.3rem;
          padding: 0.2rem 0.4rem;
        }
      }
    }

    #carousel-container {
      button {
        height: 0.2rem;
        width: 0.8rem;
        margin: 0rem 0.4rem;
      }
    }
  }
}
