#mouse-lerp {
  position: absolute;
  border: 4px solid white;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  opacity: 0.2;
  transform-origin: top left;
  transform: translate(-50%, -50%);
}
