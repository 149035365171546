@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif;
  font-weight: 400;
  line-height: 1.25;
  color: white;
  overflow: hidden; /* Hide scrollbars */

  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2);
    pointer-events: all;
  }
}

a {
  color: white;
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-50 {
  height: 50%;
}

::-moz-selection {
  /* Code for Firefox */
  color: black;
  background: white;
}

::selection {
  color: black;
  background: white;
}
