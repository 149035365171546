@import '../../../scss/_variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.3);
  clip-path: polygon(0 0, 100% 0%, 100% 75%, 0% 100%);

  h2,
  span {
    margin: 0;
    padding: 0;
    color: white;
  }

  &__language-box {
    border: none;
    outline: none;
    margin-top: 0.2rem;
    height: 1.4rem;
    width: 3.5rem;
    border-radius: 0.7rem;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    padding: 0rem 0.2rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }

    div {
      background-color: rgba(0, 0, 0, 0.4);
      height: 1.2rem;
      width: 2rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      span {
        font-size: 0.7rem;
        padding-top: 0.1rem;
      }
    }
  }

  @media #{$thin},#{$xthin} {
    padding: 1rem 1rem 1.5rem 1rem;
    h2 {
      font-weight: 400;
      font-size: 1.5rem;
    }
    span {
      font-weight: 50;
      font-size: 0.7rem;
    }
  }

  @media #{$small-thin},#{$small-wide} {
    padding: 0.5rem 0.5rem 0.7rem 1rem;
    h2 {
      font-weight: 400;
      font-size: 1rem;
    }
    span {
      font-weight: 50;
      font-size: 0.7rem;
    }
  }

  @media #{$wide} {
    padding: 1.5rem 1rem 2rem 2rem;
    h2 {
      font-weight: 400;
      font-size: 2rem;
    }
    span {
      font-weight: 50;
      font-size: 1rem;
    }
  }
}
