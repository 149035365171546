@import '../../scss/variables';

#accueil-container {
  position: relative;
  height: calc(100vh - 15rem);
  height: calc(var(--vh, 1vh) * 100 - 15rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    h3 {
      opacity: 0;
    }

    p {
      text-align: justify;
      font-size: 1rem;
      font-weight: 100;
      opacity: 0;
    }

    button {
      font-size: 2rem;
      font-weight: 700;
      background-color: transparent;
      outline: none;
      border: none;
      color: white;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      opacity: 0;

      &:hover svg {
        margin-left: 1rem;
      }

      svg {
        vertical-align: middle;
        font-size: 2.5rem;
        transition: all 1s ease;
      }
    }
  }

  @media #{$wide} {
    div {
      width: 25rem;
      margin-left: 10%;

      h3 {
        font-size: 1.7rem;
        font-weight: 400;
      }

      p {
        max-width: 20rem;
        margin: 2.2rem 0 2rem 0;
      }

      button {
        margin-left: 12rem;
      }

      svg {
        margin-left: -0.2rem;
      }
    }
  }

  @media #{$thin},#{$xthin},#{$small-thin},#{$small-wide} {
    height: calc(100vh - 11rem);
    height: calc(var(--vh, 1vh) * 100 - 11rem);

    div {
      margin: 0;

      p {
        max-width: 80%;
      }

      svg {
        margin-left: 0rem;
      }
    }
  }

  @media #{$thin},#{$xthin} {
    div {
      width: 80%;

      h3 {
        font-size: 2rem;
        font-weight: 100;
      }

      p {
        margin: 1.2rem 0 1rem 0;
      }
    }
  }

  @media #{$small-thin},#{$small-wide} {
    div {
      width: 50%;
      margin-left: 10%;

      h3 {
        font-size: 1.5rem;
        font-weight: 100;
      }

      p {
        font-size: 0.8rem;
        margin: 0.5rem 0 0.5rem 0;
      }

      button {
        font-size: 1.5rem;
      }
    }
  }
}
